import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "create-a-self-check-tab"
    }}>{`Create a Self-Check Tab`}</h1>
    <hr></hr>
    <p>{`To create a tab that links out to self-check within your mobile app, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and click on Mobile Apps from the left side menu options. Choose the mobile app or template where you want to create the self-check tab.`}</li>
      <li parentName="ol">{`Create a new tab by selecting Create new Tab above the folder list in the Tab Manager and enter a name for the self-check tab. Click 'Create'.`}</li>
      <li parentName="ol">{`Select the newly created tab and select 'Edit'.`}</li>
      <li parentName="ol">{`Under Tab Contents, select 'Library Self-check' and specify the locations that will provide this option within the app. Ensure the libraries listed correspond to the channel(s) that have been configured for self-check.`}</li>
      <li parentName="ol">{`Enable 'Enforce Location Check' if you want your patron's device to be in range of the GPS coordinates associated with that library location in order to check out or check in items. `}</li>
    </ol>
    <ul>
      <li parentName="ul">{`If this is disabled, your app will pick the first self-check enabled channel and uses those settings. `}</li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Customize the rest of the self-check tab according to your preferences for how it should appear in the app. `}</li>
      <li parentName="ol">{`Once all settings are adjusted, click 'Save' to save the changes. `}</li>
      <li parentName="ol">{`When directed back to the Mobile App screen, click 'Save' at the bottom to update your app. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      